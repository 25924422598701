<template>
  <div class="user" onselectstart="javascript:return false;">
    <Header />
    <div class="userbody">
      <aside>
        <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo">
          <div v-for="(item, index) in menu" :key="index">
            <div @click="linkTo(item.path)">
              <el-menu-item :index="item.path" class="icon-item">
                <img alt="" class="user-icon" :src="item.iconSrc" />
                <span slot="title">{{ $t("messages."+item.name) }}</span>
              </el-menu-item>
            </div>
          </div>
        </el-menu>
      </aside>
      <main><router-view></router-view></main>
    </div>
    <div class="errWindow">
      <el-dialog :modal="false" :title="errTitle" :visible.sync="errDialogVisible" :close-on-click-modal="false">
        <div class="info">
          <i style="width: 22px; height: 22px; padding-right: 10px; color: #ec3838; font-size: 22px"
            :class="isError ? 'iconfont icon-sousuoshanchu' : 'iconfont icon-jinggao'"></i>
          <span>{{ errInfo }}</span>
          <br />
        </div>
        <span slot="footer" class="dialog-footer">
          <div>
            <el-button type="primary" @click="errDialogVisible = false"
              size="mini">{{ $t('messages.sure') }}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Header from "@/components/homes/header/Header.vue";
import img1 from "../../assets/icons/personnel/个人空间.svg";
import img2 from "../../assets/icons/personnel/团队空间.svg";
import img3 from "../../assets/icons/personnel/用户信息.svg";
export default {
  components: {
    Header,
  },
  data() {
    return {
      menu: [
        { path: "/user/personal", name: "personalCenter", iconSrc: img1 },
        { path: "/user/teaminfo", name: "teamInfo", iconSrc: img2 },
        { path: "/user/userinfo", name: "userInfo", iconSrc: img3 },
      ],
      //错误弹窗
      errDialogVisible: false,
      errTitle: "",
      errInfo: "",
      isError: true,
    };
  },
  mounted() {
    bus.$on("errDialog", (data) => {
      this.errDialog(this.$t("messages.wrong"), data, true);
    });
  },
  methods: {
    linkTo(path) {
      this.$router.push(path);
    },
    errDialog(title, info, err) {
      this.errTitle = title;
      this.errInfo = info;
      this.isError = err;
      this.errDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-image: linear-gradient(to bottom right, #68f7ff, #c26fa1);
  .userbody {
    flex: 1;
    display: flex;
    .el-menu-vertical-demo {
      box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.17);
      height: 100vh;
    }
    aside {
      width: 5%;
      height: 100%;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      /deep/.el-menu-item {
        height: auto;
        margin: 30px 0;
      }
      /deep/.el-menu {
        border-right: none;
        background-color: transparent;
      }
      /deep/ .is-active {
        background-color: #dce6f8;
        border-left: solid 4px #0c7de6;
      }
      .icon-item {
        // background: #DCE6F8;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 14px;
        font-weight: 400;
        color: #378ddd;
        text-align: center;
        // justify-content: center;
        span {
          height: 20px;
          line-height: 20px;
          padding-bottom: 15px;
        }
      }
      .user-icon {
        height: 30px;
        padding-top: 15px;
      }
    }
    main {
      height: 100%;
      width: 100%;
      background: #f4f4f4;
    }
  }
}
</style>